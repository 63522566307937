import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <section className="section hero is-fullheight section-404">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="title has-text-centered has-text-primary is-size-1">
              NO HEMOS ENCONTRADO LO QUE ESTABAS BUSCANDO
            </h1>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
